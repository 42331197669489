const getInitialState = () => ({
  isLoading: false,
  employees: [],
  employee: null,
  subscriptions: [],
  emailLogs: [],
  smsLogs: [],
  isLoadingChildData: false,
});

const employee = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'SESSION_READ_SUCCESS': {
      const {
        data: {
          relationships: {
            employee: { id, attributes },
          },
        },
      } = action.data;

      return {
        ...state,
        me: {
          id,
          ...attributes,
        },
      };
    }
    case 'EMPLOYEE_SEARCH_FAILED': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'EMPLOYEE_SEARCH_SUCCESS': {
      const employees = action.data.data.map((actionEmployee) => {
        const { id, attributes, relationships } = actionEmployee;

        return {
          id,
          ...attributes,
          ...relationships,
        };
      });

      return {
        ...state,
        isLoading: false,
        employees,
      };
    }
    case 'EMPLOYEE_SEARCH_LOADING':
    case 'EMPLOYEE_READ_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'EMPLOYEE_READ_SUCCESS': {
      const {
        data: { id, attributes, relationships },
      } = action.data;

      return {
        ...state,
        isLoading: false,
        employee: {
          id,
          ...attributes,
          ...relationships,
        },
      };
    }
    case 'EMPLOYEE_SUBSCRIPTIONS_READ_SUCCESS': {
      const subscriptions = action.data.data;

      return {
        ...state,
        isLoadingChildData: false,
        subscriptions,
      };
    }

    case 'EMPLOYEE_MAIL_EVENTS_READ_SUCCESS': {
      const { data, links } = action.data;

      const emailLogs = data.map((log) => {
        const { id, attributes } = log;

        return {
          id,
          ...attributes,
        };
      });

      return {
        ...state,
        isLoadingChildData: false,
        emailLogs,
        links,
      };
    }
    case 'EMPLOYEE_SMS_EVENTS_READ_SUCCESS': {
      const smsLogs = action.data.data.map((log) => {
        const { id, attributes } = log;

        return {
          id,
          ...attributes,
        };
      });

      return {
        ...state,
        isLoadingChildData: false,
        smsLogs,
      };
    }
    case 'EMPLOYEE_MAIL_EVENTS_READ_LOADING':
    case 'EMPLOYEE_SMS_EVENTS_READ_LOADING':
    case 'EMPLOYEE_SUBSCRIPTIONS_READ_LOADING': {
      return {
        ...state,
        isLoadingChildData: true,
      };
    }
    case 'EMPLOYEE_SEARCH_RESET': {
      return {
        ...state,
        employees: [],
        isLoading: false,
      };
    }
    case 'EMPLOYEE_RESET': {
      return getInitialState();
    }
    default:
      return state;
  }
};

export default employee;
