import React, { Component, Fragment } from 'react';

import NavLinkTab from 'components/NavLinkTab';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { LinkButton } from '@peakon/bedrock/react/button';
import { TabNavigation } from '@peakon/bedrock/react/navigation';
import { View, Spinner, Avatar, Button } from '@peakon/components';

import Actions from './Actions';
import EmployeeEmailLogs from './Emails';
import EmployeeResponses from './Responses';
import EmployeeSMSLogs from './SMS';
import { EmployeeSubscriptions } from './Subscriptions';
import * as EmployeeActions from '../../actions/EmployeeActions';
import { showErrorNotification } from '../../actions/NotificationActions';
import env from '../../client/env';
import { getAbbreviation } from '../../utils/abbreviation';
import { getDashboardURL } from '../../utils/getDashboardURL';
import getImgixUrl from '../../utils/getImgixUrl';
import Audits from '../Audits';
import BreadCrumbs from '../BreadCrumbs';
import EmployeeStatusBadge from '../EmployeeStatusBadge';
import PrivateRoute from '../PrivateRoute';
import ResourceHeader from '../ResourceHeader';
import RightsFilter from '../RightsFilter';

import styles from './styles.css';

class Employee extends Component {
  componentDidMount() {
    const {
      employeeActions: { read },
      match: {
        params: { id },
      },
    } = this.props;

    read(id);
  }

  componentWillUnmount() {
    const {
      employeeActions: { resetAll },
    } = this.props;
    resetAll();
  }

  render() {
    const { isLoading, employee } = this.props;

    if (isLoading || !employee) {
      return <Spinner />;
    }

    const {
      employee: {
        id,
        name,
        avatar,
        identifier,
        externalId,
        createdAt,
        deletedAt,
        anonymizedAt,
        account: {
          id: accountId,
          attributes: { email, bouncedAt, locale, localeEffective, timezone },
        },
      },
    } = this.props;

    const companyId = get(this.props, 'employee.company.id');
    const companyName = get(this.props, 'employee.company.attributes.name');
    const accessPossible = get(
      this.props,
      'employee.company.attributes.accessPossible',
    );

    const isEmailBounced = Boolean(bouncedAt);
    const isDeleted = Boolean(deletedAt);
    const isAnonymized = Boolean(anonymizedAt);

    const company = {
      id: companyId,
      ...get(this.props, 'employee.company.attributes', {}),
    };

    const primarySubdomain =
      this.props.employee.company?.relationships?.companySubdomains?.find(
        (s) => s.attributes.primary,
      )?.attributes.subdomain;

    return (
      <View className={styles.container}>
        <ResourceHeader blocked={isDeleted}>
          <BreadCrumbs>
            <BreadCrumbs.Link to="/employees">Employees</BreadCrumbs.Link>
            <BreadCrumbs.Link to={`/employees/${id}`} isLast>
              {name}
            </BreadCrumbs.Link>
          </BreadCrumbs>
          <View className={styles.headerContent}>
            <View className={styles.employeeHeader}>
              <View className={styles.employeeLogo}>
                <Avatar
                  src={getImgixUrl({
                    src: avatar,
                    fit: 'crop',
                    height: 116,
                    width: 116,
                  })}
                  abbreviation={getAbbreviation(name)}
                  size="large"
                  rounded
                />
              </View>
              <View className={styles.employeeInfo}>
                <ResourceHeader.Heading>
                  {name}
                  <View className={styles.employeeStatus}>
                    {isDeleted && !isAnonymized && (
                      <EmployeeStatusBadge status="deleted" />
                    )}
                    {isDeleted && isAnonymized && (
                      <EmployeeStatusBadge status="anonymized" />
                    )}
                  </View>
                </ResourceHeader.Heading>
                {companyId && (
                  <Link to={`/companies/${companyId}`}>{companyName}</Link>
                )}
                <span>•</span>
                {createdAt && (
                  <Fragment>
                    <span>Created {moment(createdAt).format('ll')}</span>
                    <span>•</span>
                  </Fragment>
                )}
                {email && (
                  <span>
                    <a href={`mailto:${email}`}>{email}</a>
                  </span>
                )}
                {identifier && (
                  <Fragment>
                    <span>•</span>
                    <span>
                      {companyName} Employee Id: {identifier}
                    </span>
                  </Fragment>
                )}
                <Fragment>
                  <span>•</span>
                  <span>Peakon Employee Id: {id}</span>
                </Fragment>
                {accountId && (
                  <Fragment>
                    <span>•</span>
                    <span>Peakon Account Id: {accountId}</span>
                  </Fragment>
                )}
                {externalId && (
                  <Fragment>
                    <span>•</span>
                    <span>ExternalId: {externalId}</span>
                  </Fragment>
                )}
                <Fragment>
                  <span>•</span>
                  <span>Timezone: {timezone || '(not set)'}</span>
                </Fragment>
                {(locale || localeEffective) && (
                  <Fragment>
                    <span>•</span>
                    <span>
                      Locale:{' '}
                      {locale ||
                        (localeEffective
                          ? `(inferred: ${localeEffective})`
                          : '(not set)')}
                    </span>
                  </Fragment>
                )}
              </View>
            </View>
            <View className={styles.employeeActions}>
              {!isAnonymized && isDeleted && (
                <RightsFilter rights={['backoffice:employee:admin:all']}>
                  <Button
                    size="small"
                    type="muted"
                    onClick={this.handleAnonymize}
                  >
                    Anonymize
                  </Button>
                </RightsFilter>
              )}

              {!isDeleted && accessPossible && (
                <RightsFilter rights={['backoffice:employee:admin']}>
                  <LinkButton
                    size="small"
                    variant="primary"
                    href={getDashboardURL({
                      pathname: '/login',
                      searchParams: { employee_id: id },
                      subdomain: primarySubdomain,
                    })}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Log in as {name}
                  </LinkButton>
                </RightsFilter>
              )}

              {isEmailBounced && (
                <RightsFilter rights={['backoffice:employee:admin']}>
                  <Button
                    size="small"
                    type="muted"
                    onClick={this.unbounceEmail}
                  >
                    Unbounce Email
                  </Button>
                </RightsFilter>
              )}
            </View>
          </View>
          <View className={styles.footer}>
            <TabNavigation aria-label="Employee Tab Navigation">
              <NavLinkTab exact to={`/employees/${id}`}>
                Responses
              </NavLinkTab>
              <NavLinkTab exact to={`/employees/${id}/email-logs`}>
                Email logs
              </NavLinkTab>
              <NavLinkTab exact to={`/employees/${id}/sms-logs`}>
                SMS logs
              </NavLinkTab>
              <NavLinkTab exact to={`/employees/${id}/subscriptions`}>
                Subscriptions
              </NavLinkTab>
              {env.environment !== 'production' && (
                <NavLinkTab exact to={`/employees/${id}/actions`}>
                  Actions
                </NavLinkTab>
              )}
              <NavLinkTab exact to={`/employees/${id}/audits`}>
                Audits
              </NavLinkTab>
            </TabNavigation>
          </View>
        </ResourceHeader>
        <div data-testid="employee-page-content" className={styles.bodyContent}>
          {isLoading && this.renderLoading()}
          <Switch>
            <PrivateRoute
              path={this.props.match.path}
              exact
              component={EmployeeResponses}
              employee={this.props.employee}
            />
            <PrivateRoute
              path={`${this.props.match.path}/email-logs`}
              component={EmployeeEmailLogs}
              employee={this.props.employee}
            />
            <PrivateRoute
              path={`${this.props.match.path}/sms-logs`}
              component={EmployeeSMSLogs}
              employee={this.props.employee}
            />
            <PrivateRoute
              path={`${this.props.match.path}/subscriptions`}
              component={EmployeeSubscriptions}
              employee={this.props.employee}
            />
            <PrivateRoute
              path={`${this.props.match.path}/actions`}
              component={Actions}
              employee={this.props.employee}
              email={email}
            />
            <PrivateRoute
              path={`${this.props.match.path}/audits`}
              component={Audits}
              company={company}
              employee={this.props.employee}
            />
          </Switch>
        </div>
      </View>
    );
  }

  handleAnonymize = () => {
    if (!confirm('Are you sure you want to anonymize this employee?')) {
      return;
    }

    const {
      dispatch,
      history,
      employeeActions: { anonymize },
      employee: { id, name },
    } = this.props;

    const inputName = prompt('Please enter the full name of the employee:');
    if (inputName.toLowerCase() !== name.toLowerCase()) {
      dispatch(
        showErrorNotification({
          title: 'The name entered did not match the employee name',
        }),
      );

      return;
    }

    anonymize(id);

    history.push('/employees');
  };

  unbounceEmail = () => {
    const {
      employeeActions: { clearMailFeedback },
      employee: { id },
    } = this.props;

    clearMailFeedback(id);
  };
}

Employee.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  employeeActions: PropTypes.object,
  employee: PropTypes.object,
  isLoading: PropTypes.bool,
};

Employee.defaultProps = {
  employee: null,
  isLoading: true,
};

const mapStateToProps = (state) => {
  return {
    employee: state.employee.employee,
    isLoading: state.employee.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  employeeActions: bindActionCreators(EmployeeActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
