import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Heading3 } from '@peakon/bedrock/react/typography';
import { Stack } from '@peakon/bedrock/react-alpha/stack';
import { View, Spinner, Toggle } from '@peakon/components';

import * as EmployeeActions from '../../../actions/EmployeeActions';

import styles from './styles.css';

/** @param {NotificationSubscriptions} subscriptions */
function mapSubscriptions(subscriptions) {
  /** @type {Record<SubscriptionChannel, NotificationSubscriptions>} */
  const mappedSubscriptions = {
    mail: [],
    slack: [],
    'ms-teams': [],
  };

  for (const subscription of subscriptions) {
    const channel = subscription.id.split('_')[0];

    if (subscription.attributes.channel === channel) {
      mappedSubscriptions[channel].push(subscription);
    }
  }

  return mappedSubscriptions;
}

/**
 * @param {ReturnType<typeof mapSubscriptions>} mappedSubscriptions
 * @param {SubscriptionChannel} channel
 */
function isChannelEmpty(mappedSubscriptions, channel) {
  const channelSubscriptions = mappedSubscriptions[channel];
  return (
    channelSubscriptions.filter(
      (subscription) => subscription.id.split('_')[0] === channel,
    ).length === 0
  );
}

export function EmployeeSubscriptions() {
  const { getSubscriptions, updateSubscriptions } = EmployeeActions;
  const dispatch = useDispatch();
  const {
    isLoadingChildData,
    subscriptions = [],
    accountId,
  } = useSelector((state) => {
    return {
      accountId: state.session.accountId,
      subscriptions: state.employee.subscriptions,
      isLoadingChildData: state.employee.isLoadingChildData,
    };
  });
  const [employeeSubscriptions, setEmployeeSubscriptions] =
    useState(subscriptions);

  /** @type {NotificationSubscriptions} */
  const activeSubscriptions = employeeSubscriptions.length
    ? employeeSubscriptions
    : subscriptions;

  const mappedSubscriptions = mapSubscriptions(activeSubscriptions);

  /** @param {NotificationSubscriptions[number]} subscription */
  function toggleSubscription(subscription) {
    const updatedSubscription = {
      ...subscription,
      attributes: { subscribed: !subscription.attributes.subscribed },
    };

    dispatch(updateSubscriptions(accountId, { data: [updatedSubscription] }));

    const updatedState = activeSubscriptions.map((sub) => {
      if (sub.id === subscription.id) {
        return {
          ...sub,
          attributes: {
            ...sub.attributes,
            subscribed: !sub.attributes.subscribed,
          },
        };
      }

      return sub;
    });

    setEmployeeSubscriptions(updatedState);
  }

  useEffect(() => {
    dispatch(getSubscriptions(accountId));
  }, [accountId, dispatch, getSubscriptions]);

  if (isLoadingChildData) {
    return <Spinner />;
  }

  return (
    <View className={styles.container}>
      <Stack direction="vertical" spacing="24">
        {!isChannelEmpty(mappedSubscriptions, 'mail') ? (
          <ChannelSubscription
            channel="mail"
            title="Email"
            subscriptions={mappedSubscriptions.mail}
            onToggleSubscription={toggleSubscription}
          />
        ) : null}

        {!isChannelEmpty(mappedSubscriptions, 'slack') ? (
          <ChannelSubscription
            channel="slack"
            title="Slack"
            subscriptions={mappedSubscriptions.slack}
            onToggleSubscription={toggleSubscription}
          />
        ) : null}

        {!isChannelEmpty(mappedSubscriptions, 'ms-teams') ? (
          <ChannelSubscription
            channel="ms-teams"
            title="Microsoft Teams"
            subscriptions={mappedSubscriptions['ms-teams']}
            onToggleSubscription={toggleSubscription}
          />
        ) : null}
      </Stack>
    </View>
  );
}

/**
 *
 * @param {object} props
 * @param {SubscriptionChannel} props.channel
 * @param {string} props.title
 * @param {NotificationSubscriptions} props.subscriptions
 * @param {(subscription: NotificationSubscriptions[number]) => void} props.onToggleSubscription
 *
 */
function ChannelSubscription({
  channel,
  title,
  subscriptions,
  onToggleSubscription,
}) {
  return (
    <Stack direction="vertical" spacing="04" data-testid={`channel-${channel}`}>
      <Heading3 level={3}>{title}</Heading3>
      <table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <td>Template</td>
            <td>Status</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => {
            const status = subscription.attributes.subscribed
              ? 'subscribed'
              : 'unsubscribed';

            return (
              <tr key={subscription.id}>
                <td>{subscription.attributes.id}</td>
                <td style={{ textTransform: 'capitalize' }}>
                  <span className={styles[status]}>{status}</span>
                </td>
                <td className={styles.actions}>
                  <Toggle
                    checked={subscription.attributes.subscribed}
                    onChange={() => onToggleSubscription(subscription)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Stack>
  );
}

ChannelSubscription.propTypes = {
  channel: PropTypes.string,
  title: PropTypes.string,
  subscriptions: PropTypes.array,
  onToggleSubscription: PropTypes.func,
};
